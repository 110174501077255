import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as CheckInIcon } from "feather-icons/dist/icons/log-in.svg";
import { ReactComponent as CheckOutIcon } from "feather-icons/dist/icons/log-out.svg";
import { ReactComponent as ShieldIcon } from "feather-icons/dist/icons/shield-off.svg";
import { ReactComponent as CoffeeIcon } from "feather-icons/dist/icons/coffee.svg";

import TeamIllustrationSrc from "images/team-illustration-2.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`w-full mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Thank you for choosing Soca Guesthouse!",
  heading = (
    <>
     You have successfully completed your reservation.
    </>
  ),
  description1 = "Before arrival, you will receive an SMS and email with a link to the self-check-in form. After filling out the form, you will receive further information regarding your stay. Please find details about your reservation below.",
  description2 = "If you have any questions or need further assistance, please feel free to contact our front desk at +386 31 773 888 or via email at book@socaguesthouse.com.",

  features = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [

  ];

  if (!features) features = defaultFeatures;

  const [reservationData, setReservationData] = useState(() => {
   const storedReservationData = localStorage.getItem('reservationData');
   return storedReservationData ? JSON.parse(storedReservationData) : null;
 });

 const mailData = JSON.parse(localStorage.getItem('reservationData'));


  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description1}</Description>
            <Features>
              <Feature>
                <FeatureIconContainer><BriefcaseIcon /></FeatureIconContainer>
                <FeatureText>
                  <FeatureHeading>{reservationData.selectedRoomName}</FeatureHeading>
                  <FeatureDescription>(Reservation ID: {reservationData.bookId})</FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIconContainer><CheckInIcon /></FeatureIconContainer>
                <FeatureText>
                  <FeatureHeading>{reservationData.checkin.substring(0,10)}</FeatureHeading>
                  <FeatureDescription>(15:00)</FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIconContainer><CheckOutIcon /></FeatureIconContainer>
                <FeatureText>
                  <FeatureHeading>{reservationData.checkout.substring(0,10)}</FeatureHeading>
                  <FeatureDescription>(11:00)</FeatureDescription>
                </FeatureText>
              </Feature>
              <Feature>
                <FeatureIconContainer><ShieldIcon /></FeatureIconContainer>
                <FeatureText>
                  <FeatureHeading>Non-refundable price </FeatureHeading>
                  <FeatureDescription>({reservationData.selectedRoomPrice} €)</FeatureDescription>
                </FeatureText>
              </Feature>
            </Features>
            <Description>{description2}</Description>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <Image imageSrc={reservationData.selectedRoomImg} />
        </ImageColumn>
      </TwoColumn>
    </Container>
  );
};
