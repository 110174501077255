import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useNavigation } from 'react-router-dom';
import axios from 'axios';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {Link} from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import 'react-phone-number-input/style.css'
import '../../styles/react-calendar.css';


import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import { ReactComponent as CheckInIcon } from "feather-icons/dist/icons/log-in.svg";
import { ReactComponent as CheckOutIcon } from "feather-icons/dist/icons/log-out.svg";

import LoadingSpinner from "components/sgh/spinner.js";

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-primary-500 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 xl:mt-0`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:pb-8 text-center md:text-left`;

const Heading = tw(SectionHeading)`mt-0 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium`
const PaymentRow = tw.div`[m:-4px]`;
const AgreeingTC = tw.p`mt-4 text-sm text-gray-700 max-w-lg`;
const Subheading = tw(SubheadingBase)`mt-8 text-left`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 pl-4 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;


export default ({
  heading = <><span tw="text-primary-500">Book</span><wbr/> Your Stay Now!</>,
  description = "Fill out the form below to complete your reservation process",
  subheading1 = "Guest Information:",
  subheading2 = "Payment Information:",
  submitButtonText = "Make a reservation",
  agree = "*By submitting this form, you agree to our Terms and Conditions",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const location = useLocation();
  const data = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [bookID, setBookID] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const firstNight = data.checkin.substring(0,10);
  const checkout = new Date(data.checkout);
  const lastNight = new Date(checkout.setDate(checkout.getDate()-1)).toISOString().substring(0,10);

  const handleSubmit = async (event) => {
    event.preventDefault();


    if (!stripe || !elements || !firstName || !lastName || !email || !phoneNumber) {
      return;
    }

    setIsLoading(true);

    localStorage.removeItem('reservationData');
    localStorage.setItem('reservationData', JSON.stringify({
      firstname: firstName,
      lastname: lastName,
      email: email,
      phoneNumber: phoneNumber,
      numGuests: data.guests,
      selectedRoomImg: data.selectedRoomImg,
      selectedRoomName: data.selectedRoomName,
      selectedRoomPrice:data.selectedRoomPrice,
      selectedRoomId:data.selectedRoomId,
      checkin: data.checkin,
      checkout: data.checkout,
    }));



    //before confirmPayment check if room is still available

    //if room is still available, make a tmpReservation


    //else return to homepage
    const reservationData = {
      "roomId": data.selectedRoomId,
      "status": "1",
      "firstNight": firstNight,
      "lastNight": lastNight,
      "numAdult": data.guests,
      "guestFirstName": firstName,
      "guestName": lastName,
      "guestEmail": email,
      "guestPhone": phoneNumber,
      "guestCountry": country,
      "price": data.selectedRoomPrice,
      "refererEditable": "socaguesthouse.com",
      "checkAvailability": true,
    }

    try {
      const response = await axios.post('/api/setBooking', reservationData);
      // Handle the API response as needed
      //console.log(response.data);
      //return;
      if(response.data.bookId){
        //console.log(response.data.bookId)
        const reservationDataString = localStorage.getItem('reservationData');
        let reservationData = JSON.parse(reservationDataString);
        reservationData.bookId = response.data.bookId;
        const updatedReservationDataString = JSON.stringify(reservationData);
        localStorage.setItem('reservationData', updatedReservationDataString);

        try {

          const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
            },
            redirect: 'if_required'
          })

          if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            setError(result.error.message);
            //if payment was unsuccessful delete reservation
            const reservationData = {
              "bookId": response.data.bookId,
              "status": "0",
              "message": result.error.message
            }
            const cancelTmpReservation = await axios.post('/api/setBooking', reservationData);
            setIsLoading(false);

          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
              const mailData = {
                bookId: response.data.bookId,
                checkIn: data.checkin,
                checkOut: data.checkout,
                lastname: lastName,
                firstname: firstName,
                roomName: data.selectedRoomName,
                email: email
              }

              try {

                const sendConfirmationMail = await axios.post('/api/send-confirmation-mail', mailData);
                //console.log(sendConfirmationMail);

              } catch (error) {
                console.error('Error sending confirmation mail:', error);
                setError('Error sending confirmation mail.');
                setIsLoading(false);
              }

              window.location.replace(window.location.origin+'/thankyou');
            }

          } catch (error){

           console.error('Error with payment', error);
           setError('Payment declined');
           setIsLoading(false);
        }
      };
      if (response.data.error){
        setError('Sorry, no availability');
        setIsLoading(false);
        return;
      }
    } catch (error) {
      // Handle error
      console.error(error);
      setError('Please, try again later.');
      setIsLoading(false);
      return;
    }
  };

  const renderSubmitButton = (
    <div>
    <PrimaryButton disabled={!stripe}>{submitButtonText}</PrimaryButton>
    {agree && <AgreeingTC>{agree}</AgreeingTC>}
    </div>
  )

  const renderLoadingSpinner = (
  <div>
    <LoadingSpinner />
    <Subheading>Reservation in process... Do not leave this page.</Subheading>
  </div>
  )

  return (
    <Container>
      <TwoColumn>
        <CardColumn>
          <Card>
            <CardImage imageSrc={data.selectedRoomImg} />
            <CardText>
              <CardHeader>
                <CardType>Total price:</CardType>
                <CardPrice>
                  <CardPriceAmount>{data.selectedRoomPrice}</CardPriceAmount> EUR
                </CardPrice>
              </CardHeader>
              <CardTitle>{data.selectedRoomName}</CardTitle>
              <CardMeta>
                <CardMetaFeature>
                  <CheckInIcon /> {data.checkin.substring(0,10)}
                </CardMetaFeature>
                <CardMetaFeature>
                  <CheckOutIcon /> {data.checkout.substring(0,10)}
                </CardMetaFeature>
              </CardMeta>
              {isLoading ? <div></div> : <CardAction><Link to="/">Change Selection</Link></CardAction>}
            </CardText>
          </Card>
        </CardColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={handleSubmit}>
              {subheading1 && <Subheading>{subheading1}</Subheading>}
              <Input type="text" name="firstName" value={firstName} onChange={(event) => setFirstName(event.target.value)} placeholder="First Name" />
              <Input type="text" name="lastName" value={lastName} onChange={(event) => setLastName(event.target.value)} placeholder="Last Name" />
              <Input type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Your Email Address" />
              <PhoneInput  value={phoneNumber} onChange={setPhoneNumber} onCountryChange={setCountry} international placeholder="Your Mobile Phone Number"/>
              {subheading2 && <Subheading>{subheading2}</Subheading>}
              <PaymentRow>
              <PaymentElement />
              </PaymentRow>
              {isLoading ? <LoadingSpinner /> :
              <div>
                {error !== null  ? (
                  <Subheading>{error}</Subheading>
                ) : renderSubmitButton
                }
              </div>
              }

            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
