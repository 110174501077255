import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/sgh/Hero.js";
import Reservation from "components/sgh/ReservationGuestCalendarRoom.js";
import Reviews from "components/sgh/Reviews.js";
import FAQ from "components/sgh/FAQ.js";
import Footer from "components/sgh/footer.js";

export default () => (
  <AnimationRevealPage disabled>
    <Hero />
    <Reservation />
    <Reviews textOnLeft={true}/>
    <FAQ />
    <Footer />
  </AnimationRevealPage>
);
