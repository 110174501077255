import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Header from "components/headers/light.js";
import Footer from "components/sgh/footer.js";
import ReservationDetailsForm from "components/sgh/ReservationDetailsForm.js";

import LoadingSpinner from "components/sgh/spinner.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;


const stripePromise = loadStripe('pk_live_51NNXTgDrHsoeuaP9ZcyyJCVQVO6tZP3WfjKpNECLPjZ7dzHgNPBluND3EzEMHmZjHRQCT0d7ahsoK7D2D5tLgSAp00Cfbc5f2M');


export default () => {

  const location = useLocation();
  const data = location.state || {};
  const { numGuests, selectedRoomImg, selectedRoomName, selectedRoomPrice, selectedRoomId, checkin, checkout } = data;

  //console.log(data.selectedRoomPrice);

  const parsedSelectedRoomPrice = parseFloat(data.selectedRoomPrice)

  const centsValueSelectedRoomPrice = parsedSelectedRoomPrice*100;

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');

  const navigate = useNavigate();


  useEffect(() => {

    if (!data || Object.keys(data).length === 0) {
      navigate('/');
    }

    const fetchClientSecret = async () => {
      setIsLoading(true);
      const url = '/api/create-payment-intent';
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            amount: centsValueSelectedRoomPrice
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const result = await response.json();
        //console.log(result);
        // Store the room types in state

        setClientSecret(result.clientSecret);
        setIsLoading(false);

      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching clienSecret:', error);
      }
    };

    fetchClientSecret();
  }, [data, navigate]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  const appearance = {
    theme: 'flat',
    variables: {
      borderRadius: '0px',
      colorBackground: '#ffffff',
      fontFamily: 'Inter, system-ui',
      fontSizeBase: '0.9rem',
      fontWeightNormal: '400',
      colorText: 'rgb(203, 213, 224)'
    },
    labels: 'floating',
    rules: {
      '.Input': {
        boxShadow: 'none',
        borderBottom: '2px solid rgb(226, 232, 240)',
        color: 'rgb(41 137 206)',
        marginTop: '1.5rem'
      },
      '.Input::placeholder': {
        color: 'rgb(203, 213, 224)'
      },
      '.Input:hover': {
        border: '0',
        borderBottom: '2px solid rgb(41 137 206)'
      },
      '.Input:focus': {
        boxShadow: 'none',
        borderBottom: '2px solid rgb(41 137 206)'
      },
      '.Label--floating': {

      },
      '.Label--resting': {
        fontSize: '1rem',
        opacity: '1'
      }
    }
  };

  const renderReservationForm = (
    <div>
    {clientSecret && (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
     <ReservationDetailsForm />
    </Elements>
    )}
    </div>
  )

  return (
    <AnimationRevealPage disabled>
      <Header />

      {isLoading ? <LoadingSpinner /> : renderReservationForm }
      
      <Footer />
    </AnimationRevealPage>
  );
};
