import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {Link, useNavigate} from 'react-router-dom';
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";

import { ReactComponent as ShieldIcon } from "feather-icons/dist/icons/shield-off.svg";
import { ReactComponent as CoffeeIcon } from "feather-icons/dist/icons/coffee.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

import Calendar from 'react-calendar';
import '../../styles/react-calendar.css';
import LoadingSpinner from "components/sgh/spinner.js"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-10`;

const HeadingInfoContainer = tw.div`text-center max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`leading-tight`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Subheading = tw(SubheadingBase)`text-center mt-6 mb-3`;
const Heading = tw(
  SectionHeading
)`mt-4 text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const SelectWrapper = styled.div`
  ${tw`relative inline-block w-40 mt-1 bg-white border border-gray-300 rounded-md shadow-sm`}`;

const Select = styled.select`
  ${tw`block w-full py-2 px-3 border-transparent rounded-md bg-white text-gray-700 focus:outline-none focus:border-blue-500 focus:ring appearance-none leading-normal`}`;

const Option = styled.option`
  ${tw` bg-white text-gray-700`}`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const Card = tw.div`mx-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-primary-500 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;

export default ({
  subheading1 = "Specify the total number of guests who will be staying in the accommodation.",
  subheading2 = "Select the desired dates for your stay by marking the check-in and check-out dates on the booking calendar.",
  heading = (
    <>
       <span tw="text-primary-500">Rezervacija</span>
    </>
  ),
  primaryButtonText = "Rezervacija",
  noResultText = "We apologize for the inconvenience, but it seems that there are no available rooms for your requested dates."
}) => {

  const [roomTypes, setRoomTypes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [guests, setGuests] = useState(1);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();


  const handleDateChange = date => {
    setDateRange(date);
  };

  const handleGuestsChange = event => {
    setGuests(parseInt(event.target.value));
  };

  useEffect(() => {
    const fetchRoomTypes = async () => {
      const url = '/api/getProperty';
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        //console.log(data);
        // Store the room types in state
        setRoomTypes(data.getProperty[0].roomTypes);
        availableRooms.length = 1;
      } catch (error) {
        console.error('Error fetching room types:', error);
      }
    };

    fetchRoomTypes();
  }, []);

  const showAvailableRooms = async () => {
      if (dateRange[0] && dateRange[1] && guests) {
        const requestBody = {
          checkIn: new Date( new Date (dateRange[0]).setDate(new Date(dateRange[0].getDate()+1) ) ).toISOString(),
          checkOut: dateRange[1].toISOString(),
          propId: '46354',
          numAdult: guests,
          numChild: 0 // Adjust as needed
        };

        const url = 'https://api.beds24.com/json/getAvailabilities';

        try {
          setIsLoading(true)
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(requestBody)
          });

          const data = await response.json();

          if(data){
            const filteredRooms = Object.values(data).filter(room => room.price !== undefined);
            const filteredRoomTypes = roomTypes.filter(roomType => filteredRooms.some(room => room.roomId === roomType.roomId));
            const availableRooms = [];

            for (let i = 0; i < filteredRooms.length; i++) {
              const roomId = filteredRooms[i].roomId;

              // Find the matching object in the second JSON object
              const matchingObject = filteredRoomTypes.find((obj) => obj.roomId === roomId);

              // If a match is found, merge the objects and add it to the joined JSON array
              if (matchingObject) {
                const mergedObject = { ...filteredRooms[i], ...matchingObject };
                availableRooms.push(mergedObject);
              }
            }
            //console.log(availableRooms)
            setAvailableRooms(availableRooms);
            setIsLoading(false);
          }

        } catch (error) {
          console.error('Error fetching availabilities:', error);
          setIsLoading(false);
        }
      }
  };

  const roomsAvailableView = (
    <div style={{width:'100%'}}>
      {availableRooms.length === 0  ? (
      <Subheading>{noResultText}</Subheading>

      ) : (
      <div style={{display:'flex', flexWrap:'wrap'}}>
        {availableRooms.map((availableRoom, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={availableRoom.template8} />
                <CardText>
                  <CardHeader>
                    <CardType>Total price:</CardType>
                    <CardPrice>
                      <CardPriceAmount>{availableRoom.price}</CardPriceAmount> EUR
                    </CardPrice>
                  </CardHeader>
                  <CardTitle>{availableRoom.template7}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                      <CoffeeIcon /> {availableRoom.template6}
                    </CardMetaFeature>
                    <CardMetaFeature>
                      <ShieldIcon /> Non-refundable price
                    </CardMetaFeature>
                  </CardMeta>
                  <Link to="/checkout" state={{numGuests: guests, selectedRoomImg: availableRoom.template8, selectedRoomName: availableRoom.template7, selectedRoomPrice:availableRoom.price, selectedRoomId:availableRoom.roomId, checkin: new Date( new Date (dateRange[0]).setDate(new Date(dateRange[0].getDate()+1) ) ).toISOString(), checkout: dateRange[1].toISOString()}}><CardAction>Book Now</CardAction></Link>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </div>
      )}
    </div>
  )

  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>Book Your Stay Now!</HeadingTitle>
              <Subheading>{subheading1}</Subheading>
              <SelectWrapper>
                <Select>
                  <Option value="2">2</Option>
                  <Option value="1">1</Option>
                </Select>
              </SelectWrapper>
              <Subheading>{subheading2}</Subheading>
              <Calendar
                minDate={new Date()}
                selectRange
                value={dateRange}
                onChange={handleDateChange}
              />
              <Link onClick={showAvailableRooms} disabled={!dateRange[1] || isLoading}><PrimaryButton>Find available rooms</PrimaryButton></Link>
            </HeadingInfoContainer>
          </HeadingColumn>

          {isLoading ? <LoadingSpinner /> : roomsAvailableView }


        </ThreeColumn>
      </Content>
    </Container>
  );
};
