import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/sgh/footer.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "General terms and conditions of the Soca Guesthouse" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: January 1, 2023</p>

            <p>Inter-es d.o.o. (VAT SI59971207), is renting accommodation facilities at the Soca Guesthouse (hereinafter referred to guesthouse), on Trg svobode 19, Kanal, in accordance with the General terms and conditions:</p>

            <h2>Conclusion of the contract</h2>
            <p>
              The contract is concluded when the order is received by the guest (via e-mail, phone, website, other channels). Thus it is considered that the guest fully accepts the General terms and conditions (TS & CS).
            </p>

            <h2>Services and prices</h2>
            <p>Guesthouse must prepare the rooms booked by the guest and to implement the agreed services. The guest is obliged to pay the agreed price for the services they use. The guest is not entitled to additional services that were not promised, or that are not on offer. All prices in the price list are in euro and have included 9.5% of the value added tax (VAT). We reserve the right to change prices without prior notice.</p>

            <h2>Special offers</h2>
            <p>
              Special offers (non-refundable, last minute, first minute, campaigns, etc.). The offer, which can be considered as a special offer, is valid from the date they are posted on the website up to date, which is referred to as the end of the action. Special offers do not apply to reservations that were made before the creation of the offer or upon the expiry of the offer. The offer is only valid under the conditions described on the website.
            </p>

            <h2>The takeover of the rooms and the conditions of stay</h2>
            <p>
              Guesthouse tries to take into account the wishes of the guest. Check-in is available to guests during the 15:00 and 23:00 on the day of arrival. Upon arrival, the guest receives an electronic key (code) to the room and the street door. The guest is obliged to lock at each exit from the room. Upon arrival, the guest receives data to access wi-fi and a contact number of one person in guesthouse. The check-out must be until 11:00. If the departure time is exceeded, guesthouse may require a surcharge of additional night. Before the guest's departure, employee reviews the room for possible damage which may be charged to the guest. Smoking is not allowed in the guesthouse. For each detected violation of the smoking ban, the reservation holder will be charged a fine of 200.00 euros. Guests must take into account that the house is occupied also by other guests, so, therefore, must be considerate about the noise.
            </p>

            <h2>The payment</h2>
            <p>
              All of the services which have not yet been paid should be paid with cash upon departure. Means of payment: cash, a down payment on the bank account, online payment via Stripe payment gateway.
            </p>

            <h2>The resignation of the guests from the contract</h2>
            <p>
              Guesthouse requires a letter of cancellation to cancel reservation.
              Unless otherwise agreed upon with a special offer, the following cancellation terms and conditions apply to all reservations:
              At the time of cancellation more than 7 days before the arrival, the deposit deducted with the cost of the transaction, administration fees and the bank charges is paid back.
              At the time of the cancellation less than 7 days before the arrival, the deposit is retained.
              In the case of early departure is required to pay 100% of the price for the remaining days.
              In the event that the guest does not come on the day of booking and the booking has not been canceled, 100% of the price charged by the total of the reservation.
            </p>

            <h2>The resignation of the guesthouse from the contract </h2>
            <p>
              Guesthouse has the right to withdraw from the contract if a specific amount is not paid within the agreed deadline. Exceptionally guesthouse may withdraw from the contract also in case of the realistically justifiable reason (e.g. due to force majeure or other circumstances for which the guesthouse is not responsible, or as a result of misleading or incorrect essential information given to him by the guest).
            </p>

            <h2>Warranty</h2>
            <p>
              Guesthouse guarantees for their contractual obligations with the diligence of an honest trader. Customer's claims for damages are excluded (except in the case of damage caused by an intentional or gross breach of contractual obligations). If the service damage, disturbance or appears to be, guesthouse tried to eliminate it as soon as possible, the guest is obliged to contribute to the elimination of disorder, or that the damage is minimized. For the things which are brought with a guest, guesthouse guarantee in accordance with the law only in the case if damage is associated with the breach of duties by the guesthouse. Guesthouse assumes no responsibility for the customer's vehicle parked in front of the guesthouse, as well as for his stuff, stored in a vehicle, except in the case of intentional injury to personnel action or the effect of its gross negligence. Guesthouse takes no responsibility for items and services that are ordered in a guest well, unless this has been agreed with the customer in person and in writing (e.g. takeover of concert tickets, if the takeover has not been previously agreed upon exactly).
            </p>

            <h2>Use of data</h2>
            <p>
              All information about guests are protected, in accordance with the Law on Protection of Personal Data. It is considered that a guest agrees to use these data for the purpose of check-in procedure and any further statistical processing needed.
            </p>

            <h2>Final provisions</h2>
            <p>
              In the event of a dispute among the guests for any dispute the competent court is in Ljubljana. We reserve the right, at any time, to modify this General terms and conditions without prior notice.
            </p>

            <h1>Contact Us</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>

            <ul>
              <li>Legal address: Inter-es d.o.o., Trg svobode 19, SI-5213 Kanal</li>
              <li>Email: book@socaguesthouse.com</li>
              <li>Phone number: +386 31 773 888</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
