// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid rgb(22 57 89); /* Light grey */
  border-top: 10px solid #fff; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin: 0 auto;
}

.spinner-container{
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/spinner.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE,WAAW;EACX,YAAY;EACZ,gCAAgC,EAAE,eAAe;EACjD,2BAA2B,EAAE,UAAU;EACvC,kBAAkB;EAClB,uCAAuC;EACvC,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["@keyframes spinner {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n.loading-spinner {\r\n  width: 50px;\r\n  height: 50px;\r\n  border: 10px solid rgb(22 57 89); /* Light grey */\r\n  border-top: 10px solid #fff; /* Black */\r\n  border-radius: 50%;\r\n  animation: spinner 1.5s linear infinite;\r\n  margin: 0 auto;\r\n}\r\n\r\n.spinner-container{\r\n  width: 100%;\r\n  margin-top: 4rem;\r\n  margin-bottom: 4rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
