import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/sgh/footer.js";
import MainFeature1 from "components/sgh/aboutUsSection.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import Room21 from "images/2.1.jpg";
import logoEPK from "images/logoEPK.jpg";
import logoZW from "images/logoZW.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Us</Subheading>}
        heading="Maximum comfort in a prime location"
        description="Guest accommodation is located in the center of the Kanal ob Soča, in a more than 500-year-old house, leaning against the former medieval walls. The house has been completely renovated with the aim of providing as much comfort as possible, but at the same time it maintains a touch of homeliness that you cannot experience in hotels."
        imageSrc={Room21}
      />
      <MainFeature1
        subheading={<Subheading>Zero Waste</Subheading>}
        heading="Respect for the environment"
        description="At our guesthouse, we place great emphasis on responsible waste sorting and encourage every guest to actively participate in our waste management efforts. By diligently separating waste into appropriate categories such as paper, plastic/metal, glass, and organic materials, you contribute to our shared goal of minimizing environmental impact. While we strive for excellence in waste management, we understand that absolute perfection may not always be attainable. That's why we have established a maximum threshold of 10% unsorted waste."
        imageSrc={logoZW}
        textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading>Co-financed operation</Subheading>}
        heading="Energy renovation of Soca Guesthouse"
        description="In 2021, our guesthouse successfully completed its renovation project, which was co-financed by the European Union Regional Development Fund. This collaboration allowed us to transform our property into an energy-efficient and sustainable establishment, showcasing our commitment to responsible tourism and environmental stewardship."
        imageSrc={logoEPK}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
