import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import Room4Portrait from "images/sgh4-portrait.jpg";


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Here are some frequently asked questions about our guesthouse from our loving customers. Should you have any other questions, feel free to reach out via the email or phone.",
  imageSrc = Room4Portrait,
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "How check-in process works?",
      answer:
       "One day before your scheduled arrival, or on the same day for last-minute bookings, you will receive an SMS containing a link to our self-check-in form. Click on the link provided in the SMS to access the self-check-in form. This form allows you to provide the necessary personal details required by Slovenian law, as we are obligated to report them to the appropriate government institutions. Fill in all the required personal details accurately and completely. We assure you that your information will be handled securely and in accordance with relevant data protection regulations. Upon submitting the self-check-in form, you will be redirected to your personalized stay page. This page contains all the essential information about your reservation. On this page, you will find the entrance code that serves as your access key, allowing you to enter both the street entrance and your room door."
    },
    {
      question: "Where can I park my bicycle?",
      answer:
        "At our establishment, we offer secure bicycle parking in our garage area. In addition to bicycle parking, we also have sockets available in the garage area specifically designed for charging bicycle batteries. This feature is especially beneficial for electric bike riders who need to recharge their batteries overnight. To ensure a smooth experience, we kindly request that you inform our staff in advance about your intention to bring a bicycle. This way, they can provide you with detailed instructions upon your arrival, including the exact location of the bicycle parking area and the charging sockets."
    },
    {
      question: "Where can I park my car?",
      answer:
        "Upon completing the self-check-in form, you will be redirected to your personalized stay page. This stay page is designed to provide you with all the necessary information about your stay, including details on parking. On your stay page, you will find links that will direct you to nearby parking spaces. These links will help you navigate to the nearest parking facilities where you can securely park your car during your stay with us."
    },
    {
      question: "What is included in price?",
      answer:
        "1. All Taxes: The price you see includes all applicable taxes and fees. 2. Breakfast (for Rooms): If you book a room with us, your reservation includes a complimentary breakfast. Please note that breakfast is not included for apartment bookings. However, our apartment offer fully equipped kitchens where you can prepare your own meals at your convenience. 3. Free Wi-Fi: Stay connected during your stay with our complimentary Wi-Fi service. Whether you need to catch up on work, share your travel experiences with friends and family, or simply browse the web, we've got you covered."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
